export class ActionDataDTO {
    index: number;
    timestamp: number;

    attacker_id: string;
    attacker_hp_before: number;
    attacker_hp_after: number;
    attacker_damage_deal: number;

    defender_id: string;
    defender_hp_before: number;
    defender_hp_after: number;
    defender_damage_received: number;


    constructor(index: number, timestamp: number, 
        attacker_id: string, attacker_hp_before: number, attacker_hp_after: number, attacker_damage_deal: number, 
        defender_id: string, defender_hp_before: number, defender_hp_after: number, defender_damage_received: number) {
            this.index = index;
            this.timestamp = timestamp;

            this.attacker_id = attacker_id;
            this.attacker_hp_before = attacker_hp_before;
            this.attacker_hp_after = attacker_hp_after;
            this.attacker_damage_deal = attacker_damage_deal;

            this.defender_id = defender_id;
            this.defender_hp_before = defender_hp_before;
            this.defender_hp_after = defender_hp_after;
            this.defender_damage_received = defender_damage_received;
    }

    toString() {
        return 'Index: ' + this.index + '. Time: ' + this.timestamp + '. Attacker: Player 0' + this.attacker_id + '. Defender: Player 0' + this.defender_id + '. Damage: ' + this.attacker_damage_deal;
    }
}
