import { Scene } from "phaser";
import { UIProgressBarView } from "./UIProgressBarView";
import { DirectionEnum } from "../../enums/DirectionEnum";

export class UIProgressBarHorizontalView extends UIProgressBarView {

    constructor(scene: Scene, type: string, x1: number, y1: number, x2: number, y2: number, minValue: number, maxValue: number, value: number, direction: DirectionEnum, isEnabledHandler: boolean = false) {
        super(scene, type, x1, y1, x2, y2, minValue, maxValue, direction, isEnabledHandler);
        if (this.isEnabledHandler) {
            this.handler = this.scene.add.image(this.x1, (this.y1 + this.y2) / 2, 'handler').setOrigin(0.5, 0.5).setDepth(2);
            this.handler.setScale(0.4, 0.75);
        }
        this.setValue(value, true);
    }

    setValue(value: number, isFirstTime: boolean = false) {
        super.setValue(value, isFirstTime);
        if (isFirstTime) {
            switch (this.direction) {
                case DirectionEnum.RIGHT:
                    this.barFill = this.scene.add.image(this.x1, this.y1, 'vbar');
                    this.barFill.setOrigin(0, 0);
                    break;
                case DirectionEnum.LEFT:
                    this.barFill = this.scene.add.image(this.x2, this.y1, 'vbar');
                    this.barFill.setOrigin(1, 0);
                    break;
            }
        }
        const barXScale = (this.value / this.maxValue) * this.maxWidth / this.barFill.width;
        const barYScale = this.maxHeight / this.barFill.height;
        this.barFill.setScale(barXScale, barYScale);
        if (this.text.getBounds().width + 20 > this.barFill.getBounds().width) {
            this.text.setPosition(this.barFill.getBounds().left + 10, this.barFill.getBounds().centerY);
            this.text.setOrigin(0, 0.5);
        } else {
            this.text.setPosition(this.barFill.getBounds().right - 20, this.barFill.getBounds().centerY);
            this.text.setOrigin(1, 0.5);
        }
        
        if (this.isEnabledHandler) {
            switch (this.direction) {
                case DirectionEnum.RIGHT:
                    this.handler.x = this.barFill.getBounds().right;
                    break;
                case DirectionEnum.LEFT:
                    this.handler.x = this.barFill.getBounds().left;
                    break;
            }
            this.handler.y = this.barFill.getBounds().centerY;
        }
    }

    updateText(): void {
        this.text.setText(this.value + '/' + this.maxValue + '-' + Math.round(this.value / this.maxValue * 100) + '%');
    }

    calculateWidth(): number {
        return 0;
    }
    calculateHeight(): number {
        return 0;
    }
}