import { GameObjects, Scene } from "phaser";
import { Game } from "../../scenes/Game";

export abstract class ElementView extends GameObjects.Container {

    mType: String;

    constructor(scene: Scene, type: String, x: number, y: number, addToScene: boolean = true) {
        super (scene, x, y);
        this.mType = type;
        if (addToScene) {
            scene.add.existing(this);
        }
    }

    create() {
    }

    abstract calculateWidth(isSpacingIncluded: boolean): number;

    abstract calculateHeight(): number;

    debugGraphics() {
        if (!(this.scene as Game).debugging) { return; }
        const graphics = this.scene.add.graphics({ fillStyle: { color: 0x0011aa }, lineStyle: { color: 0xffddbb } });
        graphics.lineStyle(2, 0xe81416);
        const bounds = this.getBounds();
        graphics.lineStyle(2, 0x79c314);
        graphics.strokeRectShape(bounds);
    }
}