import { Scene } from "phaser";
import { UIProgressBarView } from "./UIProgressBarView";
import { DirectionEnum } from "../../enums/DirectionEnum";

export class UIProgressBarVerticleView extends UIProgressBarView {

    constructor(scene: Scene, type: string, x1: number, y1: number, x2: number, y2: number, minValue: number, maxValue: number, value: number, direction: DirectionEnum, isEnabledHandler: boolean = false) {
        super(scene, type, x1, y1, x2, y2, minValue, maxValue, direction, isEnabledHandler);
        if (this.isEnabledHandler) {
            this.handler = this.scene.add.image((this.x1 + this.x2) / 2, this.y1, 'handler').setOrigin(0.5, 0.5).setDepth(2);
            this.handler.setScale(0.75, 0.4);
        }
        this.setValue(value, true);
    }

    setValue(value: number, isFirstTime: boolean = false) {
        super.setValue(value, isFirstTime);
        if (isFirstTime) {
            switch (this.direction) {
                case DirectionEnum.DOWN:
                    this.barFill = this.scene.add.image(this.x1, this.y1, 'vbar');
                    this.barFill.setOrigin(0, 0);
                    break;
                case DirectionEnum.UP:
                    this.barFill = this.scene.add.image(this.x1, this.y2, 'vbar');
                    this.barFill.setOrigin(0, 1);
                    break;
            }
        }
        const barYScale = (this.value / this.maxValue) * this.maxHeight / this.barFill.height;
        const barXScale = this.maxWidth / this.barFill.width;
        this.barFill.setScale(barXScale, barYScale);
        if (this.isEnabledHandler) {
            switch (this.direction) {
                case DirectionEnum.DOWN:
                    this.handler.y = this.barFill.getBounds().bottom;
                    break;
                case DirectionEnum.UP:
                    this.handler.y = this.barFill.getBounds().top;
                    break;
            }
            this.handler.x = this.barFill.getBounds().centerX;
        }
    }

    updateText(): void {
        this.text.setText(this.value + '/' + this.maxValue + '-' + Math.round(this.value / this.maxValue * 100) + '%');
        this.text.setPosition(this.barBG.getBounds().centerX, this.barBG.getBounds().bottom + 10);
        this.text.setOrigin(0.5, 0);
    }

    calculateWidth(): number {
        return 0;
    }
    calculateHeight(): number {
        return 0;
    }
}