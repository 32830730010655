import { Scene } from 'phaser';

export class Preloader extends Scene
{
    constructor ()
    {
        super('Preloader');
    }

    init ()
    {
        //  We loaded this image in our Boot Scene, so we can display it here
        this.add.image(512, 384, 'background');

        //  A simple progress bar. This is the outline of the bar.
        this.add.rectangle(512, 384, 468, 32).setStrokeStyle(1, 0xffffff);

        //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
        const bar = this.add.rectangle(512-230, 384, 4, 28, 0xffffff);

        //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
        this.load.on('progress', (progress: number) => {

            //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
            bar.width = 4 + (460 * progress);

        });
    }

    preload ()
    {
        //  Load the assets for the game - Replace with your own assets
        this.load.setPath('assets');
        this.load.image('logo', 'logo.png');
        this.load.image("vtrack", 'ui/track.png');
        this.load.image("handler", 'ui/handler.png');
        this.load.spritesheet('vbar', 'ui/bar.png', { frameWidth: 32, frameHeight: 260 });

        this.load.spritesheet('main_idle', 'main-anims/idle.png', { frameWidth: 512, frameHeight: 512 });
        this.load.spritesheet('main_attack', 'main-anims/attack.png', { frameWidth: 512, frameHeight: 512 });
        this.load.spritesheet('main_skill', 'main-anims/skill.png', { frameWidth: 512, frameHeight: 512 });
        this.load.spritesheet('main_hit', 'main-anims/hit.png', { frameWidth: 512, frameHeight: 512 });
        this.load.spritesheet('main_die', 'main-anims/die.png', { frameWidth: 512, frameHeight: 512 });

        this.load.spritesheet('boss_idle', 'boss-anims/idle.png', { frameWidth: 512, frameHeight: 512 });
        this.load.spritesheet('boss_attack', 'boss-anims/attack.png', { frameWidth: 512, frameHeight: 512 });
        this.load.spritesheet('boss_skill', 'boss-anims/skill.png', { frameWidth: 512, frameHeight: 512 });
        this.load.spritesheet('boss_hit', 'boss-anims/hit.png', { frameWidth: 512, frameHeight: 512 });
        this.load.spritesheet('boss_die', 'boss-anims/die.png', { frameWidth: 512, frameHeight: 512 });

        // Load atlas
        this.load.atlas('ui', 'ui/nine-slice.png', 'ui/nine-slice.json');

        // Load data file
        this.load.text('combat-data-01', 'combat-data/combat-data-000.csv');
        // Load data file
        this.load.text('combat-data-02', 'combat-data/combat-data-001.csv');


        // Load sound
        this.load.audio('bg_sound', ['sound/bg_sound.ogg']);
        this.load.audio('main_attack', ['sound/main_attack.wav']);
        this.load.audio('boss_attack', ['sound/boss_attack.wav']);
        this.load.audio('die', ['sound/die.wav']);
    }

    create ()
    {
        //  When all the assets have loaded, it's often worth creating global objects here that the rest of the game can use.
        //  For example, you can define global animations here, so we can use them in other scenes.

        //  Move to the MainMenu. You could also swap this for a Scene Transition, such as a camera fade.
        this.scene.start('Game');
    }
}
