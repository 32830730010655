import { ActionDataDTO } from "./ActionDataDTO";
import * as _ from 'lodash-es';

export class ActionDataHelper {
    static fetchActionData(rawData: string): ActionDataDTO[] {
        let rawDataArr = rawData.split('\r\n');
        rawDataArr = _.tail(rawDataArr);
        const dataAsList: ActionDataDTO[] = [];
        rawDataArr.forEach(rawDataItem => {
            const item = rawDataItem.split(',');
            const action: ActionDataDTO = new ActionDataDTO(parseInt(item[0]), parseInt(item[1]), 
                item[2], parseFloat(item[3]), parseFloat(item[7]), parseFloat(item[10]),
                item[4], parseFloat(item[5]), parseFloat(item[9]), parseFloat(item[11]));
            dataAsList.push(action);
        });
        return dataAsList;
    }

    static projectFields(dataAsList: ActionDataDTO[], fields: string[]): any[] {
        const projectedData: any[] = [];
        dataAsList.forEach(item => {
            projectedData.push(_.pick(item, fields));
        });
        return projectedData;
    }

    static getMaxValueByField(dataAsList: ActionDataDTO[], field: string) {
        const data = _.maxBy(dataAsList, field);
        if (data) {
            return data[field as keyof ActionDataDTO];
        }
        return -1;
    }

    static getMinValueByField(dataAsList: ActionDataDTO[], field: string) {
        const data = _.minBy(dataAsList, field);
        if (data) return data[field as keyof ActionDataDTO];
        return -1;
    }
    
    static getDataInRange(dataAsList: ActionDataDTO[], field: string, min: number, max: number) {
        return _.filter(dataAsList, function(o: ActionDataDTO) { 
            const numberValue = parseFloat(o[field as keyof ActionDataDTO] + '')
            return  numberValue >= min && numberValue <= max
        });
    }

    static getDataGroupByField(dataAsList: ActionDataDTO[], field: string) {
        return _.groupBy(dataAsList, field);
    }

    static uniqueByField(dataAsList: ActionDataDTO[], field: string) {
        return _.uniqBy(dataAsList, field);
    }
}