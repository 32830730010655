import { ActionDataDTO } from "./ActionDataDTO";

export class PlayerDataDTO {
    id: string;
    initialHP: number;
    minHP: number;
    maxHP: number;
    actions: ActionDataDTO[];

    constructor(id: string, initialHP: number, maxHP: number, actions: ActionDataDTO[]) {
        this.id = id;
        this.initialHP = initialHP;
        this.minHP = 0; // todo: 
        this.maxHP = maxHP;
        this.actions = actions;
    }
}