import { DirectionEnum } from "../components/enums/DirectionEnum";
import { CharacterAnimationTypeEnum } from "./CharacterAnimationTypeEnum";

export class BaseCharacter extends Phaser.Physics.Arcade.Sprite {
    _idleAnim: string;
    _attackAnim: string;
    _skillAnim: string;
    _hitAnim: string;
    _dieAnim: string;
    
    _frameRate: number;
    direction: DirectionEnum;
    graphics: Phaser.GameObjects.Graphics;

    constructor(scene: Phaser.Scene, x: number, y: number, type: string, frameRate: number, direction: DirectionEnum) {
        super(scene, x, y, type);

        scene.add.existing(this);
        scene.physics.add.existing(this);

        this._idleAnim = type + '_' + CharacterAnimationTypeEnum.Idle;
        this._attackAnim = type + '_' + CharacterAnimationTypeEnum.Attack;
        this._skillAnim = type + '_' + CharacterAnimationTypeEnum.Skill;
        this._hitAnim = type + '_' + CharacterAnimationTypeEnum.Hit;
        this._dieAnim = type + '_' + CharacterAnimationTypeEnum.Die;

        this._frameRate = frameRate;
        this.direction = direction;
        this.setOrigin(0.5, 0.5);

        this.graphics = this.scene.add.graphics();
        this.updateFlip();
        this.idle();

        // this.on(Phaser.Animations.Events.ANIMATION_COMPLETE + this._attackAnim, function() {
        //     this.idle();
        // }, this);
    }

    updateFlip() {
        switch(this.direction) {
            case DirectionEnum.LEFT:
                this.setFlipX(true);
                break;
            case DirectionEnum.RIGHT:
                this.setFlipX(false);
                break;
            case DirectionEnum.UP:
                this.setFlipY(false)
                break;
            case DirectionEnum.DOWN:
                this.setFlipY(true)
                break;
        }
    }

    idle() {
        this.play({key: this._idleAnim, startFrame: Math.ceil(Math.random() * (this._frameRate - 2))});
    }

    attack() {
        this.play({key: this._attackAnim, startFrame: 0}); // .chain(this._idleAnim);
    }

    skill() {
        this.play({key: this._skillAnim, startFrame: 0}); // .chain(this._idleAnim);
    }

    hit() {
        this.play({key: this._hitAnim, startFrame: 0}); // .chain(this._idleAnim);
    }

    die() {
        this.play({key: this._dieAnim, startFrame: 0}); // .chain(this._idleAnim);
    }

    preUpdate (time: number, delta: number)
    {
        super.preUpdate(time, delta);
        // this.graphics.clear();
        // this.graphics.lineStyle(1, 0xff0000);
        // this.graphics.strokeRectShape(this.getBounds());
    }
}