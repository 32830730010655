import { Scene } from 'phaser';
import { GridSystem } from '../systems/GridSystem';
import { UIProgressBarView } from '../components/views/UI/UIProgressBarView';
import { ActionDataHandler } from '../action-stuff/ActionDataHandler';
import { ActionDataHelper } from '../action-stuff/ActionDataHelper';
import { ActionDataPlayer } from '../action-stuff/ActionDataPlayer';
import * as _ from "lodash-es";
import { UIProgressBarVerticleView } from '../components/views/UI/UIProgressBarVerticleView';
import { Speed, State } from '../action-stuff/ActionDataPlayerEnum';
import { UIProgressBarHorizontalView } from '../components/views/UI/UIProgressBarHorizontalView';
import { DirectionEnum } from '../components/enums/DirectionEnum';
import { BossCharacter } from '../action-stuff/BossCharacter';
import { MainCharacter } from '../action-stuff/MainCharacter';
import { UIButtonView } from '../components/views/UI/UIButtonView';
import { TextModel } from '../components/models/TextModel';
import { ActionDataDTO } from '../action-stuff/ActionDataDTO';

export class Game extends Scene {
    camera: Phaser.Cameras.Scene2D.Camera;
    background: Phaser.GameObjects.Image;
    msg_text: Phaser.GameObjects.Text;
    graphics: Phaser.GameObjects.Graphics;
    layout: Phaser.Geom.Rectangle[][];
    layoutRowCount: number;
    layoutColCount: number;
    debugging: boolean;
    currentActionIndex: number;

    GS: GridSystem;
    w = 1280;
    h = 720;

    adh: ActionDataHandler;
    adp: ActionDataPlayer;
    character01UI: UIProgressBarView;
    character02UI: UIProgressBarView;
    character01: MainCharacter;
    character02: BossCharacter;

    maxHP: number;
    actionInterval: number;
    replayerInterval: any;

    seekbarUI: UIProgressBarView;

    currentActionInfo: Phaser.GameObjects.Text;

    // Navigator
    playBtn: UIButtonView;
    rePlayBtn: UIButtonView;
    pauseBtn: UIButtonView;
    nextBtn: UIButtonView;
    previousBtn: UIButtonView;

    speedUpBtn: UIButtonView;
    speedDownBtn: UIButtonView; 

    // Sounds
    bgSound: any;
    mainAttackSound: any;
    bossAttackSound: any;
    dieSound: any;


    constructor() {
        super('Game');
    }

    create() {
        this.debugging = false;
        this.camera = this.cameras.main;

        // Init grid
        const rowCount = 36;
        const colCount = 36;
        this.GS = new GridSystem(this.w, this.h, rowCount, colCount);

        // Init data
        this.setupSeedData();
        this.currentActionIndex = 0;

        if (this.debugging) {
            this.debugGraphic();
        }

        this.initAnimations();
        this.initSounds();
        this.setupTop();
        this.setupMiddle();
        this.setupBottom();
        this.replay();
    }
    
    initAnimations() {
        // Main animations
        this.anims.create({
            key: 'main_idle',
            frames: this.anims.generateFrameNumbers('main_idle'),
            frameRate: 24,
            repeat: -1
        });

        this.anims.create({
            key: 'main_attack',
            frames: this.anims.generateFrameNumbers('main_attack'),
            frameRate: 24,
            repeat: 0
        });

        this.anims.create({
            key: 'main_skill',
            frames: this.anims.generateFrameNumbers('main_skill'),
            frameRate: 24,
            repeat: 0
        });

        this.anims.create({
            key: 'main_hit',
            frames: this.anims.generateFrameNumbers('main_hit'),
            frameRate: 24,
            repeat: 0
        });

        this.anims.create({
            key: 'main_die',
            frames: this.anims.generateFrameNumbers('main_die'),
            frameRate: 24,
            repeat: 0
        });

        // Boss animations
        this.anims.create({
            key: 'boss_idle',
            frames: this.anims.generateFrameNumbers('boss_idle'),
            frameRate: 24,
            repeat: -1
        });

        this.anims.create({
            key: 'boss_attack',
            frames: this.anims.generateFrameNumbers('boss_attack'),
            frameRate: 24,
            repeat: 0
        });

        this.anims.create({
            key: 'boss_skill',
            frames: this.anims.generateFrameNumbers('boss_skill'),
            frameRate: 24,
            repeat: 0
        });

        this.anims.create({
            key: 'boss_hit',
            frames: this.anims.generateFrameNumbers('boss_hit'),
            frameRate: 24,
            repeat: 0
        });

        this.anims.create({
            key: 'boss_die',
            frames: this.anims.generateFrameNumbers('boss_die'),
            frameRate: 24,
            repeat: 0
        });
    }

    initSounds() {
        this.bgSound = this.sound.add('bg_sound');
        this.bgSound.setLoop(true);
        this.bgSound.setVolume(0.6);
        this.bgSound.play();

        this.mainAttackSound = this.sound.add('main_attack');
        this.bossAttackSound = this.sound.add('boss_attack');
        this.dieSound = this.sound.add('die');
    }

    setupLayout() {
        const w = this.w / this.layoutColCount;
        const h = this.h / this.layoutRowCount;
        for (let row = 0; row < this.layoutRowCount; row++) {
            const rowItems = [];
            for (let col = 0; col < this.layoutColCount; col++) {
                const x = col * w;
                const y = row * h;
                const cell = new Phaser.Geom.Rectangle(x, y, w, h);
                rowItems.push(cell);
            }
            this.layout.push(rowItems);
        }
    }

    setupSeedData() {
        const rawData = this.cache.text.get('combat-data-02');
        this.adh = new ActionDataHandler(ActionDataHelper.fetchActionData(rawData));
        this.adp = new ActionDataPlayer(this.adh, Speed.Normal);
    }

    setupTop() {

    }

    setupMiddle() {

        // Setup attribute HP
        const p1TL = this.GS.cell(5, 10);
        const p1BR = this.GS.cell(6, 18);
        this.character01UI = new UIProgressBarVerticleView(this, 'character01', p1TL.x, p1TL.y, p1BR.x, p1BR.y, 0, this.adh.p1Data.maxHP, this.adh.p1Data.maxHP, DirectionEnum.UP);
        const p1pTL = p1TL.GRM(4).GBM(2);
        this.character01 = new MainCharacter(this, p1pTL.x, p1pTL.y - 10, 81, DirectionEnum.RIGHT);

        const p2TL = this.GS.cell(30, 10);
        const p2BR = this.GS.cell(31, 18);
        this.character02UI = new UIProgressBarVerticleView(this, 'character02', p2TL.x, p2TL.y, p2BR.x, p2BR.y, 0, this.adh.p2Data.maxHP, this.adh.p2Data.maxHP, DirectionEnum.UP);
        const p2pTL = p2TL.GLM(4).GBM(3);
        this.character02 = new BossCharacter(this, p2pTL.x, p2pTL.y, 81, DirectionEnum.RIGHT);
    }

    setupBottom() {
        // Setup seekbar
        // const seebarTL1 = this.GS.cell(5, 28);
        // const seebarBR1 = this.GS.cell(31, 29);
        // this.seekbarUI = new UIProgressBarHorizontalView(this, 'seekbar', seebarTL1.x, seebarTL1.y, seebarBR1.x, seebarBR1.y, 0, this.adp.totalTime, 0, DirectionEnum.LEFT);

        const seebarTL2 = this.GS.cell(5, 28);
        const seebarBR2 = this.GS.cell(31, 29);
        this.seekbarUI = new UIProgressBarHorizontalView(this, 'seekbar', seebarTL2.x, seebarTL2.y, seebarBR2.x, seebarBR2.y, 0, this.adp.totalTime, 0, DirectionEnum.RIGHT, true);

        // Setup current action info
        
        this.currentActionInfo = this.add.text(this.scale.width / 2, seebarTL2.top - 40, '', {color: 'red', fontStyle: 'strong', fontSize: 60}).setOrigin(0.5, 0.5);

        // Setup navigate button
        // Play pause next previous
        const playBtnTL = this.GS.cell(18, 31);
        const playBtnBR = this.GS.cell(18, 32);
        this.playBtn = new UIButtonView(this, 'navigateBtn', playBtnTL.x, playBtnTL.y, playBtnBR.x, playBtnBR.y, new TextModel('Play'), 'yellow_circle');
        this.pauseBtn = new UIButtonView(this, 'navigateBtn', playBtnTL.x, playBtnTL.y, playBtnBR.x, playBtnBR.y, new TextModel('||'), 'blue_button05');
        this.rePlayBtn = new UIButtonView(this, 'navigateBtn', playBtnTL.x, playBtnTL.y, playBtnBR.x, playBtnBR.y, new TextModel('(R)'), 'blue_button05');
        
        this.rePlayBtn.setVisible(false);
        this.pauseBtn.setVisible(false);
    
        this.playBtn.emitter.on('my_pointerdown', () => {
            this.setADPState(State.Playing);
        });

        this.rePlayBtn.emitter.on('my_pointerdown', () => {
            this.adp.reset();
            this.setADPState(State.Playing);
            this.replayAnAction(this.adp.latestAction);

        });

        this.pauseBtn.emitter.on('my_pointerdown', () => {
            this.setADPState(State.Paused);
        });

        const previousBtnTL = this.GS.cell(16, 31);
        const previousBtnBR = this.GS.cell(16, 32);
        this.previousBtn = new UIButtonView(this, 'navigateBtn', previousBtnTL.x, previousBtnTL.y, previousBtnBR.x, previousBtnBR.y, new TextModel('<'), 'blue_button05');

        this.previousBtn.emitter.on('my_pointerdown', () => {
            this.adp.goPrevious();
            // clearInterval(this.replayerInterval);
            this.replayAnAction(this.adp.latestAction);
        });
        
        const nextBtnTL = this.GS.cell(20, 31);
        const nextBtnBR = this.GS.cell(20, 32);
        this.nextBtn = new UIButtonView(this, 'navigateBtn', nextBtnTL.x, nextBtnTL.y, nextBtnBR.x, nextBtnBR.y, new TextModel('>'), 'blue_button05');

        this.nextBtn.emitter.on('my_pointerdown', () => {
            this.adp.goNext();
            // clearInterval(this.replayerInterval);
            this.replayAnAction(this.adp.latestAction);
        });
    }

    setADPState(state: State) {
        this.playBtn.setVisible(false);
        this.rePlayBtn.setVisible(false);
        this.pauseBtn.setVisible(false);
        switch(state) {
            case State.Playing:
                this.pauseBtn.setVisible(true);
                break;
            case State.Paused:
                this.playBtn.setVisible(true);
                break;
            case State.Stopped:
                this.rePlayBtn.setVisible(true);
                break;
        }

        this.adp.state = state;
    }

    replay() {
        let isSomeoneDie = false;
        this.replayerInterval = setInterval(() => {
            if (this.adp.state === State.Paused) {
                return;
            }
            // console.log('loop: ' + ++loopIndex);
            const actionInRange = this.adp.tick();
            if (actionInRange.length === 0) { isSomeoneDie = false; }
            _.each(actionInRange, (action) => {
                isSomeoneDie = this.replayAnAction(action);
            });
            // console.log(this.adp.eslapsedTime);
            
            if (isSomeoneDie || this.adp.isEnded()) {
                this.setADPState(State.Stopped);
            }
        }, this.adp.deltaTime);
    }

    replayAnAction(action: ActionDataDTO): boolean {
        let isSomeoneDie: boolean = false;
        console.log(action.toString());
        this.currentActionInfo.setText(action.toString());
        if (action.attacker_id === this.adh.p1Data.id) {
            this.character01.attack();
            this.mainAttackSound.play();
            this.character01UI.setValue(action.attacker_hp_after);
            this.character02UI.setValue(action.defender_hp_after);
            this.character02.hit();
            if (action.attacker_hp_after <= 0) {
                this.character01.die();
                isSomeoneDie = true;
            }
            if (action.defender_hp_after <= 0) {
                this.character02.die();
                isSomeoneDie = true;
            }
        } else {
            this.character02.attack();
            this.bossAttackSound.play();
            this.character01UI.setValue(action.defender_hp_after);
            this.character02UI.setValue(action.attacker_hp_after);
            this.character01.hit();
            if (action.attacker_hp_after <= 0) {
                this.character02.die();
                isSomeoneDie = true;
            }
            if (action.defender_hp_after <= 0) {
                this.character01.die();
                isSomeoneDie = true;
            }
        }
        if (isSomeoneDie) {
            this.dieSound.play();
        }
        this.seekbarUI.setValue(this.adp.eslapsedTime);
        return isSomeoneDie;
    }

    /**
     * Debugging functions
     */

    debugGraphic() {
        const graphics = this.add.graphics({ fillStyle: { color: 0x00aa00 }, lineStyle: { color: 0xab00aa } });

        graphics.clear();
        graphics.lineStyle(2, 0x00bbaa);
        const points = [];
        for (let row = 0; row < this.GS.grid.rowCount; row++) {
            for (let col = 0; col < this.GS.grid.colCount; col++) {
                const cell = this.GS.cell(col, row);
                this.add.text(cell.x, cell.y, row + ',' + col, { 'fontSize': 10, color: 'black'});
                graphics.strokeRectShape(cell);
                // const point = Phaser.Geom.Rectangle.GetCenter(cell);
                // points.push(point);
                // graphics.fillPointShape(point, 5);
            }
        }

        graphics.lineStyle(1, 0x00aa00);
    }
}
