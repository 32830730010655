export enum Speed {
    Slow = 0.25,
    Normal = 1,
    Fast = 5
}

export enum State {
    Playing = 0,
    Paused = 1,
    Stopped = 2
}