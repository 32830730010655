import { ActionDataDTO } from "../action-stuff/ActionDataDTO";

export class ArrayUtils {
    static getRandomItem(arr: any[]) {
        return arr[Math.floor(Math.random() * arr.length)];
    }

    static toDict(dataAsList: ActionDataDTO[], keyName: string) {
        const dataAsDict: Record<string, ActionDataDTO> = {};
        dataAsList.forEach(item => {
            const key: string = item[keyName as keyof ActionDataDTO] + '';
            dataAsDict[key] = item;
        });
        return dataAsDict;
    }
}