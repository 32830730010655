import { Scene } from "phaser";
import { UIView } from "./UIView";
import { Cell } from "../../../systems/Cell";
import { DirectionEnum } from "../../enums/DirectionEnum";

export abstract class UIProgressBarView extends UIView {

    minValue: number;
    maxValue: number;
    value: number;

    maxWidth: number;
    maxHeight: number;
    startCell: Cell;

    barBG: any;
    bgXScale: number;
    bgYScale: number
    barFill: any;

    direction: DirectionEnum;
    handler: any;
    isEnabledHandler: boolean;
    text: Phaser.GameObjects.Text;

    constructor(scene: Scene, type: string, x1: number, y1: number, x2: number, y2: number, minValue: number, maxValue: number, direction: DirectionEnum, isEnableHandler: boolean = false) {
        super(scene, type, x1, y1, x2, y2);
        this.isEnabledHandler = isEnableHandler;
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.barBG = this.scene.add.image(this.x1, this.y1, 'vtrack');
        this.maxWidth = x2 - x1 ;
        this.maxHeight = y2 - y1;
        this.bgXScale = this.maxWidth / this.barBG.width;
        this.bgYScale = this.maxHeight / this.barBG.height;
        this.barBG.setScale(this.bgXScale, this.bgYScale);
        this.direction = direction;
        this.barBG.setOrigin(0, 0);
        this.text = this.scene.add.text(this.barBG.getBounds().centerX, this.barBG.getBounds().bottom, '', {fontSize: 20, fontStyle: 'bold', color: 'red'});
        this.text.setDepth(1);
    }

    setValue(value: number, isFirstTime: boolean = false) {
        if (value > this.maxValue) {
            value = this.maxValue;
        } else if (value < this.minValue) {
            value = this.minValue;
        }
        this.value = value;

        this.updateText();
    }

    abstract updateText(): void;

    calculateWidth(): number {
        return 0;
    }
    calculateHeight(): number {
        return 0;
    }
}