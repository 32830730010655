import { ActionDataDTO } from "./ActionDataDTO";
import { ActionDataHandler } from "./ActionDataHandler";
import { Speed, State } from "./ActionDataPlayerEnum";

export class ActionDataPlayer {
    adh: ActionDataHandler;
    speed: Speed;
    state: State;

    totalTime: number;
    eslapsedTime: number;
    selectedIndex: number;
    selectedTimestamp: number;
    latestAction: ActionDataDTO;

    deltaTime: number;
    originalStepTime: number;
    stepTime: number;

    constructor(adh: ActionDataHandler, speed: Speed = Speed.Normal, state: State = State.Paused) {
        this.adh = adh;
        this.deltaTime = 10;
        this.originalStepTime = this.deltaTime;
        this.setSpeed(speed);
        this.state = state;

        this.totalTime = this.adh.getTimelineLength();
        this.eslapsedTime = 0;
        this.selectedIndex = 0;
        this.selectedTimestamp = 0;
        this.latestAction = this.adh.getActionAtIndex(0);
    }

    setSpeed(speed: Speed) {
        this.speed = speed;
        this.stepTime = this.speed * this.originalStepTime;
    }

    tick() {
        const from = this.adh.getTimelineMinValue() + this.eslapsedTime + 1;
        const to = this.adh.getTimelineMinValue() + this.eslapsedTime + this.stepTime;
        const actionsInRange = this.adh.getActionInTimeRange(from, to);
        if (actionsInRange.length > 0) {
            this.latestAction = actionsInRange[actionsInRange.length - 1];
        }
        this.eslapsedTime = this.eslapsedTime + this.stepTime;
        return actionsInRange;
    }

    reset() {
        this.eslapsedTime = 0;
        this.selectedIndex = 0;
        this.latestAction = this.adh.getActionAtIndex(0);
    }

    isEnded() {
        return this.eslapsedTime >= this.totalTime;
    }

    goPrevious() {
        if (this.latestAction.index <= 0) {return;}
        let previousAction = this.adh.getActionAtIndex(this.latestAction.index - 1);
        this.latestAction = previousAction;
        this.eslapsedTime = this.latestAction.timestamp - this.adh.getTimelineMinValue();
    }

    goNext() {
        if (this.latestAction.index >= this.adh.getTotalAction() - 1) {return;}
        let nextAction = this.adh.getActionAtIndex(this.latestAction.index + 1);
        this.latestAction = nextAction;
        this.eslapsedTime = this.latestAction.timestamp - this.adh.getTimelineMinValue();
    }
}