import { ArrayUtils } from "../utils/ArrrayUtils";
import { ActionDataDTO } from "./ActionDataDTO";
import { ActionDataHelper } from "./ActionDataHelper";
import { PlayerDataDTO } from "./PlayerDataDTO";

export class ActionDataHandler {
    dataAsList: ActionDataDTO[];
    dataAsDict: Record<string, ActionDataDTO>;

    p1Data: PlayerDataDTO;
    p2Data: PlayerDataDTO;

    constructor(data: ActionDataDTO[]) {
        this.dataAsList = data;
        this.dataAsDict = ArrayUtils.toDict(data, 'timestamp');
        this.constructPlayersData();
    }

    getTotalAction(): number {
        return this.dataAsList.length;
    }

    getActionAtIndex(index: number) {
        return this.dataAsList[index];
    }
   
    getActionInTimeRange(start: number, end: number) {
        return ActionDataHelper.getDataInRange(this.dataAsList, 'timestamp', start, end);
    }

    getActionAtTimestamp(timestamp: number) {
        return this.getActionInTimeRange(timestamp, timestamp);
    }

    getTimelineMinValue(): number {
        return parseInt(ActionDataHelper.getMinValueByField(this.dataAsList, 'timestamp') + '');
    }

    getTimelineMaxValue(): number {
        return parseInt(ActionDataHelper.getMaxValueByField(this.dataAsList, 'timestamp') + '');
    }

    getTimelineLength(): number {
        return this.getTimelineMaxValue() - this.getTimelineMinValue();
    }

    constructPlayersData() {
        const uniquePlayerIDs = ActionDataHelper.uniqueByField(this.dataAsList, 'attacker_id');
        const allPlayerData = ActionDataHelper.getDataGroupByField(this.dataAsList, 'attacker_id');
        
        const player1Id = uniquePlayerIDs[0].attacker_id;
        const player1Actions = allPlayerData[player1Id];
        const player1MaxHP = parseFloat(ActionDataHelper.getMaxValueByField(player1Actions, 'attacker_hp_before') + '');

        const player2Id = uniquePlayerIDs[1].attacker_id;
        const player2Actions = allPlayerData[player2Id];
        const player2MaxHP =  parseFloat(ActionDataHelper.getMaxValueByField(player1Actions, 'defender_hp_before') + '');

        this.p1Data = new PlayerDataDTO(player1Id, player1Actions[0].attacker_hp_before, player1MaxHP, player1Actions);
        this.p2Data = new PlayerDataDTO(player2Id, player1Actions[0].defender_hp_before, player2MaxHP, player2Actions);
    }
}