import { Scene } from "phaser";
import { UIView } from "./UIView";
import { TextModel } from "../../models/TextModel";
import { DepthUtil } from "../../../utils/DepthUtil";

export class UIButtonView extends UIView {
    emitter: Phaser.Events.EventEmitter;

    constructor(scene: Scene, type: string,  x1: number, y1: number, x2: number, y2: number, textModel: TextModel, spriteName: string, textStyle: any = {}) {
        super(scene, type, x1, y1, x2, y2);
        const btnBG = this.scene.add.nineslice(0, 0, 'ui', spriteName, 50, 15, 15, 5).setInteractive().setScale(1, 1).setDepth(DepthUtil.ButtonBGDepth);
        const btnText = this.scene.add.text(0, 0, textModel.text, textStyle).setDepth(DepthUtil.TextDepth);
        this.add(btnBG);
        this.add(btnText);
        btnText.setOrigin(0.5, 0.5);
        btnBG.on('pointerdown', () => {
            this.emitter.emit('my_pointerdown');
        });

        this.emitter = new Phaser.Events.EventEmitter();
    }

    calculateWidth(): number {
        return 0;
    }
    calculateHeight(): number {
        return 0;
    }
}